export default [
  {
    path: "/clients",
    name: "patients",
    component: () => import("../views/patients/Index.vue"),
    meta: {
      auth: true,
      title: "Clients",
    },
  },
  {
    path: "/clients/create",
    name: "patients.add",
    component: () => import("../views/patients/Create.vue"),
    meta: {
      auth: true,
      admin: true,
      title: "Create Client",
    },
  },
  {
    path: "/clients/:id",
    component: () => import("../views/patients/profile/Index.vue"),
    children: [
      {
        path: "budget",
        component: () => import("../views/patients/profile/billing/Nav.vue"),
        children: [
          {
            path: "",
            name: "patients.budget",
            component: () =>
              import("../views/patients/profile/billing/Maxhours.vue"),
          },
          {
            path: "budget-charts",
            name: "patients.budget-charts",
            component: () =>
              import("../views/patients/profile/billing/BudgetCharts.vue"),
          },
        ],
        meta: {
          auth: true,
        },
      },
      {
        path: "budget-charts",
        name: "patients.budget.chart",
        component: () =>
          import("../views/patients/profile/billing/ProviderBudgetCharts.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "",
        component: () => import("../views/patients/Profile.vue"),
        children: [
          {
            path: "",
            name: "patients.dashboard",
            component: () =>
              import("../views/patients/profile/dashboard/Index.vue"),
          },
          {
            path: "profile",
            name: "patients.basic",
            component: () => import("../views/patients/profile/Basic.vue"),
          },
          {
            path: "address",
            name: "patients.address",
            component: () =>
              import("../views/patients/profile/address/Index.vue"),
          },
          {
            path: "phones",
            name: "patients.phones",
            component: () =>
              import("../views/patients/profile/phones/Index.vue"),
          },
          {
            path: "emails",
            name: "patients.emails",
            component: () =>
              import("../views/patients/profile/emails/Index.vue"),
          },
          {
            path: "classes",
            name: "patients.classes",
            component: () =>
              import("../views/patients/profile/classifications/Index.vue"),
          },
          {
            path: "services",
            name: "patients.services",
            component: () =>
              import("../views/patients/profile/services/Index.vue"),
          },
        ],
        meta: {
          auth: true,
        },
      },
      {
        path: "providers",
        name: "patients.providers",
        component: () =>
          import("../views/patients/profile/providers/Index.vue"),
      },
      {
        path: "medical",
        name: "patients.medical",
        component: () => import("../views/patients/profile/medical/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "documents",
        name: "patients.documents",
        component: () =>
          import("../views/patients/profile/documents/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "invoices",
        name: "patients.invoices",
        component: () => import("../views/patients/profile/invoices/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "timeline",
        name: "patients.timeline",
        component: () => import("../views/patients/profile/timeline/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "contacts",
        name: "patients.contacts",
        component: () => import("../views/patients/profile/contacts/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "notes",
        name: "patients.notes",
        component: () => import("../views/patients/profile/notes/Index.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "notes/:userId/:appointmentId/create",
        name: "patients.notes.create",
        component: () => import("../views/patients/profile/notes/Create.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "notes/print",
        name: "patients.notes.print",
        component: () =>
          import("../views/patients/profile/notes/PrintNotes.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "notes/:noteId",
        name: "patients.notes.edit",
        component: () => import("../views/patients/profile/notes/Edit.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "c3-integration",
        name: "client.c3.integration",
        component: () => import("../views/myProfile/C3Integration.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "symptoms",
        component: () => import("../views/patients/profile/symptoms/Nav.vue"),
        children: [
          {
            path: "",
            name: "patients.symptoms",
            component: () =>
              import("../views/patients/profile/symptoms/Questionnaire.vue"),
          },
          {
            path: "reports",
            name: "patients.reports",
            component: () =>
              import("../views/patients/profile/symptoms/Reports.vue"),
          },
        ],
        meta: {
          auth: true,
          noClient: true,
        },
      },
      {
        path: "symptomsDiary",
        component: () =>
          import("../views/patients/profile/symptomsDiary/Nav.vue"),
        children: [
          {
            path: "",
            name: "patients.symptomsDiary",
            component: () =>
              import(
                "../views/patients/profile/symptomsDiary/Questionnaire.vue"
              ),
          },
          {
            path: "reports",
            name: "patients.symptomsDiaryReports",
            component: () =>
              import("../views/patients/profile/symptomsDiary/Reports.vue"),
          },
        ],
        meta: {
          auth: true,
        },
      },
      {
        path: "reports/print",
        name: "patients.reports.print",
        component: () =>
          import("../views/patients/profile/symptoms/PrintReports.vue"),
        meta: {
          auth: true,
          noClient: true,
        },
      },
      {
        path: "client-reports/print",
        name: "patients.clientReports.print",
        component: () =>
          import("../views/patients/profile/symptomsDiary/PrintReports.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "shared-recordings",
        name: "patients.shared-recordings",
        component: () =>
          import("../views/patients/profile/SharedRecordings.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "availability",
        name: "patients.availability",
        component: () => import("../views/patients/profile/Availability.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "okaya",
        component: () => import("../views/patients/profile/okaya/Nav.vue"),
        children: [
          {
            path: "",
            name: "patients.okaya.checkIn",
            component: () =>
              import("../views/patients/profile/okaya/CheckIn.vue"),
          },
          {
            path: "list",
            name: "patients.okaya.sessions",
            component: () =>
              import("../views/patients/profile/okaya/Sessions.vue"),
          },
        ],
        meta: {
          auth: true,
        },
      },
    ],
  },

  {
    path: "/clients/:id/phones/create",
    name: "patients.phones.create",
    component: () => import("../views/patients/profile/phones/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/address/create",
    name: "patients.address.create",
    component: () => import("../views/patients/profile/address/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/emails/create",
    name: "patients.emails.create",
    component: () => import("../views/patients/profile/emails/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/classes/create",
    name: "patients.classes.create",
    component: () =>
      import("../views/patients/profile/classifications/Create.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/services/create",
    name: "patients.services.create",
    component: () => import("../views/patients/profile/services/Create.vue"),
    meta: {
      auth: true,
      noClient: true,
    },
  },
];
