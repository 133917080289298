export default [
  {
    path: "/privacy-policy",
    name: "",
    component: () => import("../views/static/Privacy.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import("../views/static/Loading.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/terms",
    name: "",
    component: () => import("../views/static/Terms.vue"),
    meta: {
      title: "Terms &amp; Conditions",
    },
  },
];
